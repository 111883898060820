import React from "react";

// Shared Components
import AuthedHeader from "../../components/shared/header/AuthedHeader";

//hooks
export default function Dashboard() {
  
  return (
    <div>
      <AuthedHeader />
      <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12 ">
        <div className="mx-auto">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 m-5 justify-between">
            {/* Beginning of first column */}
            <div className="rounded overflow-hidden shadow-lg bg-white col-span-1">
              <div className="flex justify-between p-5">
                <img
                  className="w-10 inline filter-yellow"
                  src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/search.svg"
                  alt="Search"
                />
                <h2 className="inline p-5 text-yellowTT font-bold">
                  ACCOUNTS
                </h2>
                <img
                  className="w-5 inline"
                  src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                  alt="Arrow"
                />
              </div>

              <hr className="width:100%" />

              <div className="font-bold text-xl mb-2 pt-5 px-5 text-gray-500">
                Recent
              </div>

              <div className="pb-5">
                <div className="flex justify-between px-5 py-1">
                  <img
                    className="w-8 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/home.svg"
                    alt="Search"
                  />
                  <h2 className="inline p-5">113 Jackson St. NY 12302</h2>
                  <img
                    className="w-5 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                    alt="Arrow"
                  />
                </div>
                <div className="flex justify-between px-5 py-1">
                  <img
                    className="w-8 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/commercial-structures.svg"
                    alt="Search"
                  />
                  <h2 className="inline p-5">223 Selina St. NY 13203</h2>
                  <img
                    className="w-5 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                    alt="Arrow"
                  />
                </div>
                <div className="flex justify-between px-5 py-1">
                  <img
                    className="w-8 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/commercial-structures.svg"
                    alt="Search"
                  />
                  <h2 className="inline p-5">113 Jackson St. NY 12302</h2>
                  <img
                    className="w-5 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                    alt="Arrow"
                  />
                </div>
                <div className="flex justify-between px-5 py-1">
                  <img
                    className="w-8 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/home.svg"
                    alt="Search"
                  />
                  <h2 className="inline p-5">
                    5559 Strater Ave. OK 22903
                  </h2>
                  <img
                    className="w-5 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                    alt="Arrow"
                  />
                </div>
                <div className="flex justify-between px-5 py-1">
                  <img
                    className="w-8 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/commercial-structures.svg"
                    alt="Search"
                  />
                  <h2 className="inline p-5">
                    39234 Staples r Dr. CA 12312
                  </h2>
                  <img
                    className="w-5 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                    alt="Arrow"
                  />
                </div>
                <div className="flex justify-between px-5 py-1">
                  <img
                    className="w-8 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/home.svg"
                    alt="Search"
                  />
                  <h2 className="inline p-5">
                    1492 Steezy Rd. Shasta CS 123
                  </h2>
                  <img
                    className="w-5 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                    alt="Arrow"
                  />
                </div>
              </div>
            </div>

            {/* Beginning of second column */}
            <div className="rounded overflow-hidden shadow-lg bg-white col-span-1">
              <div className="flex justify-between p-5">
                <img
                  className="w-10 inline filter-yellow"
                  src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/team.svg"
                  alt="Search"
                />
                <h2 className="inline p-5 text-yellowTT font-bold">
                  TEAM
                </h2>
                <img
                  className="w-5 inline"
                  src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                  alt="Arrow"
                />
              </div>

              <hr className="width:100%" />

              <div className="pb-5">
                <div className="flex justify-between px-5 pt-3">
                  <h2 className="inline p-2 text-xl font-bold text-gray-300">
                    MB
                  </h2>
                  <h2 className="inline p-2">Micah Buller</h2>
                  <img
                    className="w-5 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                    alt="Arrow"
                  />
                </div>
                <div className="flex justify-between px-5 pt-3">
                  <h2 className="inline p-2 text-xl font-bold text-gray-300">
                    PK
                  </h2>
                  <h2 className="inline p-2">Peter Kirk</h2>
                  <img
                    className="w-5 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                    alt="Arrow"
                  />
                </div>
                <div className="flex justify-between px-5 pt-3">
                  <h2 className="inline p-2 text-xl font-bold text-gray-300">
                    SD
                  </h2>
                  <h2 className="inline p-2">Sara Dow</h2>
                  <img
                    className="w-5 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                    alt="Arrow"
                  />
                </div>
                <div className="flex justify-between px-5 pt-3">
                  <h2 className="inline p-2 text-xl font-bold text-gray-300">
                    KH
                  </h2>
                  <h2 className="inline p-2">Katie Hoffman</h2>
                  <img
                    className="w-5 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                    alt="Arrow"
                  />
                </div>
                <div className="flex justify-between px-5 pt-3">
                  <h2 className="inline p-2 text-xl font-bold text-gray-300">
                    JF
                  </h2>
                  <h2 className="inline p-2">John John Florence</h2>
                  <img
                    className="w-5 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                    alt="Arrow"
                  />
                </div>
                <div className="flex justify-between px-5 pt-3">
                  <h2 className="inline p-2 text-xl font-bold text-gray-300">
                    KS
                  </h2>
                  <h2 className="inline p-2">Kelly Slater</h2>
                  <img
                    className="w-5 inline"
                    src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                    alt="Arrow"
                  />
                </div>
              </div>
            </div>

            {/* Beginning of third column */}
            <div className="sm:col-span-1 min-w-min rounded overflow-hidden shadow-lg bg-white md:col-span-2 lg:col-span-2">
              <div className="flex justify-between p-5">
                <img
                  className="w-10 inline filter-yellow"
                  src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/work-queue.svg"
                  alt="Search"
                />
                <h2 className="inline p-5 text-yellowTT font-bold">
                  WORK QUEUE
                </h2>
                <img
                  className="w-5 inline"
                  src="https://tech-tester-micah.s3.us-east-2.amazonaws.com/next.svg"
                  alt="Arrow"
                />
              </div>

              <hr className="width:100%" />

              <ul className="bg-gray-50 rounded-md p-2 sm:p-5 xl:p-6 m-5">
                <header className="flex items-center justify-between mt-10 mb-8">
                  <h2 className="text-3xl tracking-tight font-extrabold text-gray-900">
                    December 2021
                  </h2>
                </header>

                <li>
                  <article>
                    <a
                      href="https://kirkwork.co/"
                      className="grid md:grid-cols-8 xl:grid-cols-9 items-start relative rounded-xl p-3 sm:p-5 xl:p-6 overflow-hidden hover:bg-white"
                    >
                      <h3 className="font-semibold text-gray-900 md:col-start-3 md:col-span-6 xl:col-start-3 xl:col-span-7 mb-1 ml-9 md:ml-0">
                        History of Sain Nick!
                      </h3>
                      <time
                        dateTime="2021-06-17T19:00:00.000Z"
                        className="md:col-start-1 md:col-span-2 row-start-1 md:row-end-3 flex items-center font-medium mb-1 md:mb-0"
                      >
                        <svg
                          viewBox="0 0 12 12"
                          className="w-3 h-3 mr-6 overflow-visible text-yellow-300"
                        >
                          <circle
                            cx="6"
                            cy="6"
                            r="6"
                            fill="currentColor"
                          ></circle>
                          <circle
                            cx="6"
                            cy="6"
                            r="11"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                          ></circle>
                          <path
                            d="M 6 18 V 500"
                            fill="none"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="text-gray-200"
                          ></path>
                        </svg>
                        Dec 17, 2021
                      </time>
                      <p className="md:col-start-3 md:col-span-6 xl:col-span-7 ml-9 md:ml-0">
                        St. Nicholas made his first inroads into American
                        popular culture towards the end of the 18th century. In
                        December 1773, and again in 1774, a New York newspaper
                        reported that groups of Dutch families had gathered to
                        honor the anniversary of his death.
                      </p>
                    </a>
                  </article>
                </li>

                <li>
                  <article>
                    <a
                      href="https://kirkwork.co/"
                      className="grid md:grid-cols-8 xl:grid-cols-9 items-start relative rounded-xl p-3 sm:p-5 xl:p-6 overflow-hidden hover:bg-white"
                    >
                      <h3 className="font-semibold text-gray-900 md:col-start-3 md:col-span-6 xl:col-start-3 xl:col-span-7 mb-1 ml-9 md:ml-0">
                        History of Christmas
                      </h3>
                      <time
                        dateTime="2021-06-17T19:00:00.000Z"
                        className="md:col-start-1 md:col-span-2 row-start-1 md:row-end-3 flex items-center font-medium mb-1 md:mb-0"
                      >
                        <svg
                          viewBox="0 0 12 12"
                          className="w-3 h-3 mr-6 overflow-visible text-yellow-300"
                        >
                          <path
                            d="M 6 -6 V -30"
                            fill="none"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="text-gray-200"
                          ></path>
                          <circle
                            cx="6"
                            cy="6"
                            r="6"
                            fill="currentColor"
                          ></circle>
                          <path
                            d="M 6 18 V 500"
                            fill="none"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="text-gray-200"
                          ></path>
                        </svg>
                        Dec 25, 2021
                      </time>
                      <p className="md:col-start-3 md:col-span-6 xl:col-span-7 ml-9 md:ml-0">
                        The history of Christmas trees goes back to the symbolic
                        use of evergreens in ancient Egypt and Rome and
                        continues with the German tradition of candlelit
                        Christmas trees first brought to America in the 1800s.
                        Discover the history of the Christmas tree, from the
                        earliest winter solstice celebrations to Queen
                        Victoria’s decorating habits and the annual lighting of
                        the Rockefeller Center tree in New York City.
                      </p>
                    </a>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
