import React from "react";

// Router
import { Router } from "@reach/router";

// Modules
import Login from "../modules/login/Login";
import Signup from "../modules/signup/Signup";
import Dashboard from "../modules/dashboard/Dashboard";
import EmailSentConfirmation from "../modules/email-sent-confirmation/EmailSentConfirmation";
import CompleteRegistration from "../modules/complete-registration/CompleteRegistration";
//import PageNotFound from "../modules/page-not-found/PageNotFound";

// Authentication
import PrivateRoute from "../components/shared/private-route/PrivateRoute";

// Shared Components
import Toast from "../components/shared/toast/Toast";

//Wrappers
import { AuthProvider } from "../hooks/auth";
import { ConfirmContextProvider } from "../hooks/confirm";

const App = () => {
  return (
    <AuthProvider>
      <ConfirmContextProvider>
        <Router>
          <PrivateRoute path="/app/login" component={Login} default />
          <PrivateRoute path="/app/dashboard" component={Dashboard} />
          <Signup path="/app/signup" />
          <CompleteRegistration path="/app/complete-registration" />
          <EmailSentConfirmation path="/app/email-sent-confirmation" />
          {/* <PageNotFound default /> */}
        </Router>
        <Toast />
      </ConfirmContextProvider>
    </AuthProvider>
  );
};

export default App;
