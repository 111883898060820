import * as React from "react"

import {Toaster} from "react-hot-toast";

export default function Toast(){
    return(
        <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                style: {
                    padding: '16px',
                    background: '#D5F0D7',
                    color: '#fff',
                },
                // Default options for specific types
                custom: {
                    duration: 5000,
                    position: 'top-right',
                },
                success:{
                    style: {
                        color: '#713200',
                        background: '#fafafa',
                    },
                },
            }}
        />
    )
}