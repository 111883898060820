import React, {useEffect} from "react"

import { useNavigate } from "@reach/router"
import { useAuth } from "../../../hooks/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    const { authed } = useAuth();
    const navigate = useNavigate();

    useEffect(()=> {
        async function navigateToLogin() {
            await navigate('/app/login')
        }
        async function navigateToDashboard() {
            await navigate('/app/dashboard')
        }

        if (authed === false && location.pathname !== `/app/login`) {
            navigateToLogin()
        }
        if(authed === true){
            navigateToDashboard()
        }
    },[authed, location.pathname])


    return <Component {...rest} />
}

export default PrivateRoute