import * as React from "react";

// React
import { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "@reach/router";

// SVG
import LogoSvg from "../../images/logo/logo.inline.svg";

// Services
import { post } from "../../services/ttAPICaller";

// Shared Components
import CustomToast from "../../components/shared/toast/CustomToast";

// Hooks
import useConfirm from "../../hooks/confirm";

export default function SignUp() {
  //const [signedUp, setSignedUp] = useState(false);
  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const { confirm } = useConfirm();

  const signup = async (event) => {
    event.preventDefault();

    let postBody = JSON.stringify({
      email: email,
    });

    await post("signup", postBody)
      .then(async (response) => {
        const data = await response;
        console.log("Data returned to request: " + JSON.stringify(data));
        if (data) {
          if (
            data.message ===
            "Please check your email to complete account registration."
          ) {
            //setSignedUp(true);
            navigate("/app/email-sent-confirmation", {
              state: { email: email },
            });
          } else {
            toast.custom(
              (t) => (
                <CustomToast
                  toast={t}
                  toastType={"failure"}
                  title={"Error"}
                  message={"Account could not be created. Please try again."}
                />
              ),
              { duration: 3000 }
            );
          }
        }
      })
      .catch(async (error) => {
        const isConfirmed = await confirm(
          "This email address is already in use. Would you like to resend the confirmation email?"
        );
        if (isConfirmed) {
          // RESEND EMAIL
          await post(`signup/resend`, JSON.stringify({ email: email })).then(
            (data) => {
              console.log("data returned to request: " + JSON.stringify(data));
              if (
                data.message ===
                "Please check your email to complete account registration."
              ) {
                //setSignedUp(true);
                navigate("/app/email-sent-confirmation", {
                  state: { email: email },
                });
              } else {
                toast.custom(
                  (t) => (
                    <CustomToast
                      toast={t}
                      toastType={"failure"}
                      title={"Error"}
                      message={data.message}
                    />
                  ),
                  { duration: 3000 }
                );
                navigate("/app/signup");
              }
            }
          );
          console.error("Error returned from API!", error);
        }
      });
  };

  return (
    <div
      className={"min-h-screen flex-1 md:bg-gray-100 bg-white justify-center"}
    >
      <div className="flex flex-col py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md invisible md:visible">
          <a href="/">
            <LogoSvg className={"mx-auto h-12 w-auto fill-myGray-400"} />
          </a>
        </div>
        <div className="bg-white mx-auto sm:w-full sm:h-full sm:max-w-md rounded-md px-16 py-10 md:shadow-md my-8">
          <div className="mx-auto h-12 w-auto visible md:h-0 md:invisible ">
            <a href="/">
              <LogoSvg className={"mx-auto h-12 w-auto fill-myGray-400"} />
            </a>
          </div>
          <header className="flex items-center justify-center mt-6 mb-8">
            <h2 className="text-2xl md:text-3xl tracking-tight font-extrabold text-gray-800">
              Sign Up
            </h2>
          </header>
          <form
            className="space-y-1 m-auto"
            action="#"
            method="POST"
            onSubmit={signup}
          >
            <div>
              <div className="flex items-center border-b py-2">
                <input
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                  className="appearance-none block w-full px-3 py-2 placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm md:text-lg"
                />
              </div>
            </div>

            <div className="rounded-md pt-6">
              <button
                type="submit"
                className="w-full flex shadow-md justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-bold text-white bg-brown hover:bg-brown-dark"
              >
                Submit
              </button>
            </div>
            <div className="min-h-full flex flex-col pt-6 place-items-center ">
              <label className="text-sm text-gray-800 text-center">
                Already have an account?{" "}
                <Link to="/app/login" className="hover:text-brown">
                  Log In!
                </Link>
              </label>
            </div>
          </form>
        </div>
        <div className="min-h-full flex flex-col place-items-center">
          <label className="text-xs text-gray-400 text-center">
            By submitting this form you are agreeing to our{" "}
            <Link to="/terms-of-service" className="hover:underline">
              terms of service
            </Link>{" "}
            and{" "}
            <Link to="/privacypolicy" className="hover:underline">
              privacy policy
            </Link>
            .
          </label>
        </div>
      </div>
    </div>
  );
}
