
export function maskPhone (phone) {
    const formatPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/,"($1)$2-$3");
    const maskedPhone = formatPhone.replace(/\d(?=(?:\D*\d){2})/g, '*');
    return maskedPhone;
}


export function maskEmail(email){
    let skipFirstChars = 2;
    let firstThreeChar = email.slice(0, skipFirstChars);

    let domainIndexStart = email.lastIndexOf("@");
    let maskedEmail = email.slice(skipFirstChars, domainIndexStart)
    maskedEmail = maskedEmail.replace(/./g, '*')
    let domain = email.slice(domainIndexStart, email.length);

    return firstThreeChar.concat(maskedEmail).concat(domain);
}
