import * as React from "react";

// React
import {useEffect, useState} from "react";
import { useNavigate } from "@reach/router";
import { useQueryParam, StringParam } from "use-query-params";
import toast from "react-hot-toast";

// Heroicons
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'

// Services
import {post} from "../../services/ttAPICaller";

// Shared Components
import CustomToast from "../../components/shared/toast/CustomToast";


export default function CompleteRegistration({location, data}) {
    const [passwordShown, setPasswordShown] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [confirmCode, setConfirmCode] = useQueryParam("confirm_code", StringParam)
    const [passwordMatch, setPasswordMatch] = useState(null);
    const [borderColor, setBorderColor] = useState('border-myGray-400');

    const navigate = useNavigate();

    const togglePassword = (e) => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        e.preventDefault();
        setPasswordShown(!passwordShown);
    };

    const submitForm = async (event) => {
        event.preventDefault();

        let postBody = JSON.stringify({
            "confirm_code": confirmCode,
            "first_name": firstName,
            "last_name": lastName,
            "password": password,
            "password_confirmation": passwordConfirm,
            "permission_level": 0
        });

        await post('signup/confirm', postBody).then(async response => {
            const data = await response;
            console.log('data returned to request: ' + JSON.stringify(data));
            if (data){
                if (data.message === "Account successfully created.") {
                    toast.custom((t) =>
                        (<CustomToast toast={t} toastType={'success'} title={'Success!'} message={'Your account has been successfully created.'}/>), {duration: 3000}
                    )
                    navigate('/app')
                } else {
                    toast.custom((t) =>
                        (<CustomToast toast={t} toastType={'failure'} title={'Error'} message={'Account could not be created. Please try again.'}/>), {duration: 3000}
                    )
                }
            }

    })
    }

    //For Matching Passwords
    useEffect(() => {
        if(password !== '' && passwordConfirm !== ''){
            if(passwordConfirm === password ){
                setPasswordMatch(true)
                setBorderColor('border-greenTT')
            }else{
                setPasswordMatch(false)
                setBorderColor('border-red')
            }
        }else if(password === '' && passwordConfirm === ''){
            setBorderColor('border-myGray-400')
        }

    }, [password, passwordConfirm])

    return (
        <div className={"min-h-screen flex bg-myGray-100 items-center justify-center"}>
            <div className={"grid flex items-center justify-center text-center"}>
                <h1 className={"text-3xl font-bold text-myGray-500"}>Welcome to Tech Tester</h1>
                <h2 className={"text-xl text-myGray-500"}>Complete your registration (it's free!)</h2>
                <br/>
                <form className={""}>
                    <div className={"border-b-2 border-myGray-400 my-10"}>
                        <input
                            className={"bg-myGray-100 text-center text-2xl text-myGray-500 outline-none placeholder-myGray-400"}
                            required
                            name="firstname"
                            id="firstName"
                            placeholder={"First Name"}
                            onChange={e => {
                                setFirstName(e.target.value)
                            }}
                        />
                    </div>
                    <div className={"border-b-2 border-myGray-400 mb-10"}>
                        <input
                            className={"bg-myGray-100 text-center text-2xl text-myGray-500 outline-none placeholder-myGray-400"}
                            required
                            name="lastname"
                            id="lastName"
                            placeholder={"Last Name"}
                            onChange={e => {
                                setLastName(e.target.value)
                            }}
                        />                    </div>
                    <div className={`border-b-2 mb-10 ${borderColor}`}>
                        <input
                            className={"bg-myGray-100 text-center text-2xl text-myGray-500 outline-none placeholder-myGray-400"}
                            required
                            placeholder={"Password"}
                            type={passwordShown ? "text" : "password"}
                            name="password"
                            id="password"
                            onChange={e => {
                                setPassword(e.target.value)
                            }}
                        />
                        <button className={"bg-none"} onClick={togglePassword}>
                            {passwordShown ? <EyeIcon className={"h-5 text-myGray-500"}/> : <EyeOffIcon className={"h-5 text-myGray-500"}/>}
                        </button>
                    </div>
                    <div className={`border-b-2 mb-10 ${borderColor}`}>
                        <input
                            className={"bg-myGray-100 text-center text-2xl text-myGray-500 outline-none placeholder-myGray-400"}
                            required
                            placeholder={"Confirm Password"}
                            type={passwordShown ? "text" : "password"}
                            name="password_confirm"
                            id="password_confirm"
                            onChange={e => {
                                setPasswordConfirm(e.target.value)
                            }}
                        />
                        <button className={"bg-none"} onClick={togglePassword}>
                            {passwordShown ? <EyeIcon className={"h-5 text-myGray-500"}/> : <EyeOffIcon className={"h-5 text-myGray-500"}/>}
                        </button>
                    </div>

                    <button
                        className={"bg-brown px-10 py-2 rounded-md hover:bg-brown-dark shadow-lg"}
                        onClick={submitForm}
                    >
                        <span className={"text-white font-bold"}>FINISH SIGNING UP</span>
                    </button>

                    <div className={"pt-5"}>
                        <p className={"text-xs"}>Already have an account? <a className={"underline"} href={"/login"}>Sign in.</a> </p>
                    </div>

                </form>
            </div>
        </div>
    );
}