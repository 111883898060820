import * as React from "react";

// React
import { Link } from "@reach/router";
import toast from "react-hot-toast";

// Images
import MailSentImg from "./assets/mail-sent.png";

// Services
import { post } from "../../services/ttAPICaller";

// Shared Components
import CustomToast from "../../components/shared/toast/CustomToast";

// SVGs
import LogoSvg from "../../images/logo/logo.inline.svg";

// Heroicons
import { RefreshIcon } from "@heroicons/react/outline";

export default function EmailSentConfirmation({location, data}) {

  const email = location.state.email;

  async function resendEmail() {
    await post(`signup/resend`, JSON.stringify({ email: email })).then(
      (data) => {
        console.log("data returned to request: " + JSON.stringify(data));
        if (
          data.message ===
          "Please check your email to complete account registration."
        ) {
          toast.custom(
            (t) => (
              <CustomToast
                toast={t}
                toastType={"success"}
                title={"Success!"}
                message={
                  "Email has been sent. Please check your inbox to complete your registration."
                }
              />
            ),
            { duration: 3000 }
          );
        } else {
          toast.custom(
            (t) => (
              <CustomToast
                toast={t}
                toastType={"failure"}
                title={"Error"}
                message={data.message}
              />
            ),
            { duration: 3000 }
          );
        }
      }
    );
  }

  return (
    <div
      className={"min-h-screen flex bg-myGray-100 items-center justify-center"}
    >
      <div
        className={
          "grid flex grid-cols-1 md:grid-cols-2 m-10 gap-10 items-center justify-center"
        }
      >
        <div className={"px-16 py-5 text-center justify-center"}>
          <div className={"flex items-center justify-center text-center"}>
            <Link to="/">
              <LogoSvg className={`h-12 w-auto fill-gray-300 mb-8`} />
            </Link>
          </div>
          <h1 className={"font-bold text-gray-600 text-2xl pb-2"}>
            Verification Link Sent!
          </h1>
          <p className={"text-gray-500 text-md"}>
            We emailed a confirmation link to your email. Check your inbox to
            complete your registration.
          </p>
          <br />
          <br />
          <p className={"text-gray-500 text-md"}>
            Didn't get the confirmation email?
          </p>
          <p className={"text-gray-500 text-xs mb-2"}>
            Don't forget to check your spam or junk folder.
          </p>
          <button
            className={"flex flex-row w-full items-center justify-center"}
            onClick={resendEmail}
          >
            <RefreshIcon className={"h-5 px-0 mr-2 text-greenTT"} /> 
            <h1>Resend</h1>
          </button>
        </div>
        <div className={"items-center text-center justify-center"}>
          <img
            className={"px-10 py-5 h-3/4"}
            src={MailSentImg}
            alt={"Man sending envelope on a paper airplane"}
          />
        </div>
      </div>
    </div>
  );
}
