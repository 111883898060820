import * as React from "react";

// React
import { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useLocation } from "@reach/router";

// SVG
import LogoSvg from "../../images/logo/logo.inline.svg";

// Services
import { post } from "../../services/ttAPICaller";

// Shared Components
import CustomToast from "../../components/shared/toast/CustomToast";

// Hooks
import { useAuth } from "../../hooks/auth";

// Utils
import { maskPhone, maskEmail } from "../../utils/mask";

export default function Login() {
  const [twoFactor, setTwoFactor] = useState(false);
  const [method, setMethod] = useState("");
  const [destination, setDestination] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [user, setUser] = useState(null);

  const auth = useAuth();
  //const location = useLocation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleLogin = (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;

    auth.login(email, password).then((result) => {
      
      if (result.two_factor_enabled === true) {
        setUser(result);
        setTwoFactor(true);
        setMethod(result.method);
        setDestination(result.destination);
      } else {
        setUser(result);
        navigate(state?.path || "/app/dashboard");
      }
    });
  };

  const handleTwoFactor = async (event) => {
    event.preventDefault();
    let postBody = JSON.stringify({
      user_id: user.user_id,
      email: user.email,
      method: user.method,
      destination: user.destination,
      default_flag: true,
    });
    await post("auth/request", postBody)
      .then((data) => {
        console.log("data returned to request: " + JSON.stringify(data));
        if (data) {
          if (data.success) {
            setCodeSent(true);
            setTwoFactor(false);
          } else {
            toast.custom(
              (t) => (
                <CustomToast
                  toast={t}
                  toastType={"failure"}
                  title={"Error"}
                  message={data.message}
                />
              ),
              { duration: 3000 }
            );
            navigate("/login");
          }
        } else {
          console.log("we in error section.");
          toast.custom(
            (t) => (
              <CustomToast
                toast={t}
                toastType={"failure"}
                title={"Error"}
                message={"Two Factor Request Not Authorized."}
              />
            ),
            { duration: 3000 }
          );
          navigate("/login");
        }
      })
      .catch((error) => {
        toast.custom(
          (t) => (
            <CustomToast
              toast={t}
              toastType={"failure"}
              title={"Error"}
              message={error.toString()}
            />
          ),
          { duration: 3000 }
        );
        console.error("Error returned from API!", error);
      });
  };

  const handleCode = async (event) => {
    event.preventDefault();
    const authCode = event.target.authCode.value;

    let postBody = JSON.stringify({
      user_id: user.user_id,
      email: user.email,
      method: user.method,
      destination: user.destination,
      auth_code: authCode,
    });

    await post("auth/verify", postBody)
      .then((data) => {
        console.log("data returned to request: " + JSON.stringify(data));

        if (data) {
          auth.setAuthed(true)
          setUser(data);
          localStorage.setItem("user", JSON.stringify(data));
          navigate(state?.path || "/app/dashboard");
        } else {
          console.log("we in error section.");
          toast.custom(
            (t) => (
              <CustomToast
                toast={t}
                toastType={"failure"}
                title={"Error"}
                message={"Two Factor Request Not Authorized."}
              />
            ),
            { duration: 3000 }
          );
          navigate("/app");
        }
      })
      .catch((error) => {
        toast.custom(
          (t) => (
            <CustomToast
              toast={t}
              toastType={"failure"}
              title={"Error"}
              message={"Unauthorized Request."}
            />
          ),
          { duration: 3000 }
        );
        navigate("/app");
        console.error("Error returned from API!", error);
      });
  };

  return (
    <div
      className={"min-h-screen flex-1 md:bg-gray-100 bg-white justify-center"}
    >
      <div className="flex flex-col py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md invisible md:visible">
          <a href="/">
            <LogoSvg className={"mx-auto h-12 w-auto fill-myGray-400"} />
          </a>
        </div>
        {twoFactor ? (
          <div className="bg-white mx-auto sm:w-full sm:h-full sm:max-w-md rounded-md px-16 py-10 md:shadow-md my-8">
            <div className="mx-auto h-12 w-auto visible md:h-0 md:invisible ">
              <a href="/">
                <LogoSvg className={"mx-auto h-12 w-auto fill-myGray-400"} />
              </a>
            </div>
            <header className="flex items-center justify-center mt-6 mb-0">
              <h2 className="text-2xl md:text-2xl text-center tracking-tight font-extrabold text-gray-800">
                Two Factor Authentication
              </h2>
            </header>
            <div>
              <div className="flex justify-center border-b pb-8 pt-4">
                <p>Your Security Method</p>
              </div>
            </div>
            <form
              className="space-y-1 m-auto"
              action="#"
              method="POST"
              onSubmit={handleTwoFactor}
            >
              <div className="mb-6">
                <div className="flex border-b py-2">
                  {method === "sms" ? (
                    <div>
                      <span className="pr-8">Phone Number:</span>
                      <span>{maskPhone(destination)}</span>
                    </div>
                  ) : method === "email" ? (
                    <div>
                      <span className="pr-8">Email:</span>
                      <span>{maskEmail(destination)}</span>
                    </div>
                  ) : (
                    <div>
                      <p>None Selected</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="rounded-md shadow-md">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-bold text-white bg-brown hover:bg-brown-dark"
                >
                  Send Code
                </button>
              </div>
            </form>
          </div>
        ) : codeSent ? (
          <div className="bg-white mx-auto sm:w-full sm:h-full sm:max-w-md rounded-md px-16 py-10 md:shadow-md my-8">
            <div className="mx-auto h-12 w-auto visible md:h-0 md:invisible ">
              <Link to="/">
                <LogoSvg className={"mx-auto h-12 w-auto fill-myGray-400"} />
              </Link>
            </div>
            <header className="flex items-center justify-center mt-10 mb-8">
              <h2 className="text-2xl md:text-2xl text-center tracking-tight font-extrabold text-gray-800">
                Two Factor Authentication
              </h2>
            </header>
            <form
              className="space-y-1 m-auto"
              action="#"
              method="POST"
              onSubmit={handleCode}
            >
              <div>
                <div className="flex items-center border-b py-2">
                  <p>Enter the code that was sent to you.</p>
                </div>
              </div>

              <div className="flex items-center border-b py-2">
                <input
                  id="authCode"
                  placeholder="Auth Code"
                  name="authCode"
                  type="text"
                  required
                  className="appearance-none block w-full px-3 py-2 placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm md:text-lg"
                />
              </div>

              <div className="rounded-md shadow-md">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-bold text-white bg-brown hover:bg-brown-dark"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="bg-white mx-auto sm:w-full sm:h-full sm:max-w-md rounded-md px-16 py-10 md:shadow-md my-8">
            <div className="mx-auto h-12 w-auto visible md:h-0 md:invisible ">
              <Link to="/">
                <LogoSvg className={"mx-auto h-12 w-auto fill-myGray-400"} />
              </Link>
            </div>
            <header className="flex items-center justify-center mt-10 mb-8">
              <h2 className="text-2xl md:text-3xl tracking-tight font-extrabold text-gray-800">
                Welcome Back!
              </h2>
            </header>
            <form
              className="space-y-1 m-auto"
              action="#"
              method="POST"
              onSubmit={handleLogin}
            >
              <div>
                <div className="flex items-center border-b py-2">
                  <input
                    id="email"
                    placeholder="Email Address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm md:text-lg"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center border-b py-2">
                  <input
                    id="password"
                    placeholder="Password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm md:text-lg"
                  />
                  <a href="/" className="font-medium text-sm hover:text-brown">
                    Forgot?
                  </a>
                </div>
              </div>

              <div className="grid-cols-1">
                <div className="flex items-center py-5">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-yellow-300 focus:ring-yellow-300 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember Me
                  </label>
                </div>
              </div>

              <div className="rounded-md shadow-md">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-bold text-white bg-brown hover:bg-brown-dark"
                >
                  Sign In
                </button>
              </div>
              <div className="min-h-full flex flex-col place-items-center pt-4">
                <label className="text-sm text-gray-800 text-center">
                  Don't have a business account?{" "}
                  <Link to="/app/signup" className="hover:text-brown">
                    Create One!
                  </Link>
                </label>
              </div>
            </form>
          </div>
        )}
        <div className="min-h-full flex flex-col place-items-center">
          <label className="text-xs text-gray-400 text-center">
            By logging in you are agreeing to our{" "}
            <Link to="/terms-of-service" className="hover:underline">
              terms of service
            </Link>{" "}
            and{" "}
            <Link to="/privacypolicy" className="hover:underline">
              privacy policy
            </Link>
            .
          </label>
        </div>
      </div>
    </div>
  );
}
